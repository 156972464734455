import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'setting-widgets-filter',
  data () {
    return {
      filter: {
        keywords: '',
        countries: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      filterCountries: 'settingWidgets/filterCountries',
      filterKeywords: 'settingWidgets/filterKeywords',

      basicCountries: 'setting/basicCountries'
    })
  },
  created () {
    if (!this.accessCountries) {
      this.fetchCountries().then(() => { this.setFilters() })
    }
    this.setFilters()
  },
  methods: {
    ...mapActions({
      fetchCountries: 'setting/GET_COUNTRIES',
      fetchList: 'settingWidgets/GET_LIST',
    }),
    ...mapMutations({
      changeFilter: 'settingWidgets/CHANGE_LIST_FILTER',
      setListLength: 'settingWidgets/SET_LIST_LENGTH',
      changeSkip: 'settingWidgets/CHANGE_SKIP'
    }),
    setFilters () {
      this.filter.countries = this.filterCountries
      this.filter.keywords = this.filterKeywords
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 500),
    changeFilterLocal (val, type) {
      this.changeFilter({
        type: type,
        value: val
      })
      this.sendRequest()
      // reset
      this.setListLength(0)
      this.changeSkip(0)
    }
  }
}
